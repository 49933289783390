import React from "react";
import "../assets/css/formMessage.css";

export const FormMessage = ({ correctValidation }) => {
  return (
    <div>
      {correctValidation === 2 ? (
        <div
          className="telefono"
          role="alert"
        >
          <h4>
          Número de telefone inválido!
          </h4>
          <h4>
          Por favor, tente novamente
          </h4>
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : correctValidation === 3 ? (
        <div
          className="mail"
          role="alert"
        >
          <h4>
          E-mail inválido!
          </h4>
          <h4>
          Por favor, tente novamente
          </h4>
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : correctValidation === 4 ? (
        <div
          className="error-post"
          role="alert"
        >
          <h4>
          Ocorreu um erro inesperado.
          </h4>
          <h4>
          Por favor, tente novamente
          </h4>
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : correctValidation === 5 ? (
        <div
          className="name"
          role="alert"
        >
          <h4 className="name">
          Nome inválido!
          </h4>
          <h4 className="name">
          Por favor, tente novamente
          </h4>
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : null}
    </div>
  );
};
