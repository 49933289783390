import React from "react";
import Carousel from "react-bootstrap/Carousel";

import promoAlarme1 from "../assets/img/promoAlarme1.png";
import promoAlarmeMobile1 from "../assets/img/promoAlarmeMobile1.png";
// import promoAlarme2 from "../assets/img/promoAlarme2.png";
// import promoAlarmeMobile2 from "../assets/img/promoAlarmeMobile2.png";
// import promoAlarme3 from "../assets/img/promoAlarme3.png";
// import promoAlarmeMobile3 from "../assets/img/promoAlarmeMobile3.png";

import "../assets/css/slider.css";

export const Slider = () => {
  const text = "Obtenha aconselhamento gratuito";

  return (
    <div className="slider-title">
      <div className="carousel">
        <Carousel>
          {/* <Carousel.Item>
            {window.innerWidth <= 1001 ? (
              <img
                src={promoAlarmeMobile2}
                alt={text}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={promoAlarme2}
                alt={text}
                className="d-block w-100"
              />
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth <= 1001 ? (
              <img
                src={promoAlarmeMobile3}
                alt={text}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={promoAlarme3}
                alt={text}
                className="d-block w-100"
              />
            )}
          </Carousel.Item> */}
          <Carousel.Item>
            {window.innerWidth <= 1001 ? (
              <img
                src={promoAlarmeMobile1}
                alt={text}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={promoAlarme1}
                alt={text}
                className="d-block w-100"
              />
            )}
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
