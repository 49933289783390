import React from 'react'
import "../assets/css/footer.css";
import facebookIcon from "../assets/img/facebookIcon.svg";
import phoneIcon from "../assets/img/phoneIconFooter.svg";
import mapsIcon from "../assets/img/mapsIcon.svg";

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-icons-position'>
            <a href="https://www.facebook.com/ADT-Brasil-Oficial-105688522489128" target="_blank" rel="noopener noreferrer">
                <img
                    className='facebook-icon'
                    src={facebookIcon}
                    alt='Facebook icon'
                />
            </a>
            <a href="tel: 1130422195">
                <img src={phoneIcon} alt='Phone-icon' className='phone-icon' />
            </a>
            <img
                className='maps-icon'
                src={mapsIcon}
                alt='Maps icon'
            />
        </div>
        <div className='footer-content-position'>
            <h1>
            Copyright © 2023
            </h1>
        </div>
    </div>
  )
}

export default Footer