import React, { useState, useEffect } from 'react'
import "./assets/css/App.css";
import { Logo } from "./components/Logo";
import { Floating } from "./components/Floating";
import { Slider } from "./components/Slider";
import { ContactUs } from "./components/ContactUs"
import AppSmartSecurity from './components/AppSmartSecurity';
import Footer from './components/Footer';

const App = () => {

  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="fullPage">
      {data !== null ? (
        <div className="top-bar">
          <Logo />
          <Floating data={data}/>
        </div>
      ):(<></>)}
      <div className="slider-position">
        <Slider/>
      </div>
      <div className='contact-us-position'>
        <ContactUs/>
      </div>
      <div className='smart-security-position'>
        <AppSmartSecurity/>
      </div>
      <div className='footer-position'>
        <Footer/>
      </div>
    </div>
  )
}

export default App